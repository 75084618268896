import React from 'react';
import { cloneDeep } from "lodash";
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Spinner
 } from 'reactstrap';
import List from '../List/ListFactory';
import TooltipComponent from '../TooltipComponent';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faUser,
  faInfo,
  faEnvelope,
  faUserFriends,
  faClone,
  faFileExcel,
  faLock,
  faMinusCircle,
  faHistory,
  faChessQueen,
  faUserTie,
  faHashtag
} from '@fortawesome/free-solid-svg-icons';

import CloseComponent from '../SubComponent/CloseComponent';
import GroupTitleHeader from '../SubComponent/GroupTitleHeader';
import ButtonExport from '../SubComponent/ButtonExport';
import HistoryLog from '../SubComponent/HistoryLog';
import GroupDescription from '../SubComponent/GroupDescription';
import GroupEditors from '../SubComponent/GroupEditors';
import GroupMembersView from '../GroupMembersView/GroupMembersView';

import { fetchGroupById } from '../ODD/action';
import { prepareGroupById } from '../../utils';

import { getLoggedUser } from '../../utils';
import { getLockStatus } from '../../utils/lockService';
const POLLING_LOCK_TIMER = 5000;

import { getURLPrefix } from '../../utils/baseURLService.js';
const pathname = getURLPrefix().pathname;

class GroupDetailView extends React.Component {
  constructor(props){
    super(props);

    this.editAction = this.editAction.bind(this);

    this.state = {
      groupId: props.groupId,
      current_user: getLoggedUser(),
      lock : {
        isLocked : false
      },
      group : {}
    }
  }

  componentDidUpdate(prevProps, prevState){
    var groupId = this.props.groupId;

      if(prevProps.groupId !== groupId){
        clearInterval(this.lockTimer);
        this.lockTimer = null;
        var resourceID = groupId;
        this.getAndUpdateStateWithLockStatus(resourceID);
        this.lockTimer = setInterval(() => {
          this.getAndUpdateStateWithLockStatus(resourceID)
        }, POLLING_LOCK_TIMER);
      }

    }

  componentDidMount(){
    var groupId = encodeURIComponent(this.props.groupId);

    this.props.onFetchGroupById(groupId);

    clearInterval(this.lockTimer);
    this.lockTimer = null;
    var resourceID = groupId;
    this.getAndUpdateStateWithLockStatus(resourceID);
    this.lockTimer = setInterval(() => {
      this.getAndUpdateStateWithLockStatus(resourceID)
    }, POLLING_LOCK_TIMER);

  }

  getAndUpdateStateWithLockStatus(resourceID){
    getLockStatus(resourceID)
    .then((status) => this.setState({lock:status}));
  }

  componentWillUnmount() {
      clearInterval(this.lockTimer)
      this.lockTimer = null;
    }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { groupId } = nextProps;
    const { groupById, people } = nextProps.ldap;

  if(groupId && Object.keys(groupById).length > 0 && groupById.id === groupId && people){
      var groupFormatted = prepareGroupById(groupById, people);

      return { group : groupFormatted }
    }
    return null;
  }

  editAction(){
    getLockStatus(this.props.groupId)
    .then(status => {
      if(!status.isLocked || this.state.current_user.uid === this.state.lock.authUsername){
        this.props.history.push(pathname + 'group/edit/' + encodeURIComponent(this.props.groupId));
      }
      else{
        this.setState({lock:status});
      }
    })
  }

  generateHtmlChairs(chairs){
    var html = [];
    chairs.forEach(function(chair){
      html.push(<p key={chair.id}><a href={"mailto:" + chair.email} target="_blank">{chair.fullname}</a></p>);
    });
    return html;
  }

  userAllowedToEdit(group, current_user){

    var staffGroupId = this.props.ldap.configuration.staffGroupId;

    var groupId = group.id;
    var editorsIds = group.editorsIds;

    var userAllowedToEdit = false;

    if(current_user.perm === "ADMIN"){
      userAllowedToEdit = current_user.perm === "ADMIN";
    }


    if(current_user.perm === "EDITOR" && staffGroupId !== groupId){
      if(group.editorsIds){
        userAllowedToEdit = group.editorsIds.some(contact => contact === current_user.uid);
      } else {
        userAllowedToEdit = current_user.perm === "EDITOR" && staffGroupId !== groupId;
      }
    }
    return userAllowedToEdit;

  }

  render() {
    var group = this.state.group;
    var isLoading = !group || typeof(group) !== "object" || Object.keys(group).length === 0;
    var current_user = this.state.current_user;
    var isLocked = this.state.lock.isLocked;
    var userAllowedToEdit = this.userAllowedToEdit(group, current_user);

    var isStaffGroup = this.props.ldap && this.props.ldap.configuration.staffGroupId && this.props.ldap.configuration.staffGroupId === group.id;

    var chairs = !isLoading ? this.generateHtmlChairs(group.chairs) : null;

    var viceChairs = !isLoading ? this.generateHtmlChairs(group.viceChairs) : null;

    var locationPathname = this.props.location.pathname;

    return (
      <Container fluid={true} className="groupDetailView h-100 overflow-auto">
        <div className="descriptionRow stickyRow">
          <Row>
            <Col xs={11}>
              <GroupTitleHeader group={group} isLoading={isLoading}/>
            </Col>

            <Col xs={1} className="text-right">
              <CloseComponent target={pathname + "group"} />
            </Col>

            <Col xs={12}  className="text-right">
              {!isLoading 
                ? <>
                    <ButtonExport data={group} exportTo="GROUP"/>
                    {current_user.perm === "EDITOR" || current_user.perm === "ADMIN"
                      ? <Button onClick={this.editAction} disabled={isLocked || !userAllowedToEdit} color="primary">
                          {isLocked || !userAllowedToEdit ? <FontAwesomeIcon icon={faLock} /> : null} Edit
                        </Button>
                      : null
                    }
                  </>
                : <Button disabled color="primary">
                    <FontAwesomeIcon icon={faLock}/> ...
                  </Button>
              }
            </Col>

            {!isLoading || isLocked || current_user.perm === "EDITOR" || current_user.perm === "ADMIN"
              ? <p className="text-right text-light w-100 pt-0 pb-0 pr-4 pl-4">
                  {isLocked ? <strong><FontAwesomeIcon icon={faMinusCircle} /> Someone is already editing this group.</strong> : null}
                  {!userAllowedToEdit ? <strong> {isStaffGroup ? "Only the LPOv2 administrator can edit this group." : "You are not authorized to edit this group."}</strong> : null}
                </p> 
              : null
            }
          </Row>
        </div>

        <Row className="infoBox">
          <Col>
              <Col className="min-width-37 p-0" xs={1}><TooltipComponent icon={faEnvelope} text="Group e-mail" id="email"/></Col>
              <Col xs="11">
                <p>
                  {isLoading
                    ? <div class="content-wrapper">
                        <div class="placeholder placeholder-dark w-170">
                          <div class="animated-background"></div>
                        </div>
                      </div>
                    : <a href={"mailto:" + group.email} target="_blank">{group.email}</a>
                  }
                </p>
              </Col>
          </Col>

          {!isLoading && this.state.group.chairs.length 
            ? <Col>
                <Col className="min-width-37 p-0" xs={1}><TooltipComponent icon={faChessQueen} text="Chair" id="chair"/></Col>
                <Col xs="11">{chairs}</Col>
              </Col> 
            : null 
          }

          {!isLoading && this.state.group.viceChairs.length 
            ? <Col>
                <Col className="min-width-37 p-0" xs={1}><TooltipComponent icon={faUserTie} text="Vice-chair" id="viceChair"/></Col>
                <Col xs="11">{viceChairs}</Col>
              </Col> 
            : null 
          }

          <Col>
            <Col className="min-width-37 p-0" xs={1}><TooltipComponent icon={faUser} textIcon="EFPIA" text="EFPIA contact" id="efpiaContact"/></Col>
            <Col xs="11">
              <p>
                {isLoading
                  ? <div class="content-wrapper">
                      <div class="placeholder placeholder-dark w-170">
                        <div class="animated-background"></div>
                      </div>
                    </div>
                  : <>{group.efpiaContact.fullname ? <a href={"mailto:" + group.efpiaContact.email} target="_blank">{group.efpiaContact.fullname}</a> : "EFPIA contact"}</>
                }
              </p>
            </Col>
          </Col>

          <Col>
            <Col className="min-width-37 p-0" xs={1}><TooltipComponent icon={faUserFriends} text="Member count" id="members"/></Col>
            <Col xs="11">
              <p>
                {isLoading
                  ? <div class="content-wrapper">
                      <div class="placeholder placeholder-dark w-170">
                        <div class="animated-background"></div>
                      </div>
                    </div>
                  : <><strong>{group.memberCount}</strong> members</>
                }
              </p>
            </Col>
          </Col>

          {current_user.perm === "ADMIN"
            ? <Col>
                <Col className="min-width-37 p-0" xs={1}><TooltipComponent icon={faHashtag} text="Group ID" id="gid"/></Col>
                <Col xs="11">
                  <p>
                    {isLoading
                      ? <div class="content-wrapper">
                          <div class="placeholder placeholder-dark w-170">
                            <div class="animated-background"></div>
                          </div>
                        </div>
                      : <strong>{group.gid}</strong>
                    }
                  </p>
                </Col>
              </Col>
            : null
          }
        </Row>

        <GroupMembersView 
          members={!isLoading ? group.members : []}
          locationPathname={locationPathname}
          groupId={!isLoading ? group.id : ""}
          isLoading={isLoading}
        />
        <Row>
          {!isLoading && !isStaffGroup && group.editors
            ? <Col xs={6}>
                <GroupEditors editors={group.editors}/>
              </Col>
            : null
          }
          <Col xs={!isLoading && !isStaffGroup && group.editors ? 6 : 12}>
            <HistoryLog logs={group.logs} isLoading={isLoading} />
          </Col>
        </Row>

        

      </Container>
    );
  }
}

const mapStateToProps = state => ({
  ldap: state.ldap,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  onFetchGroupById: (id) => dispatch(fetchGroupById(id))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GroupDetailView);
