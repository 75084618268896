import React from 'react';

import CollapseComponent from '../CollapseComponent/CollapseComponent';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faFileExcel, faClone } from '@fortawesome/free-solid-svg-icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { generateHtmlClipboard, generateHtmlContactDetail, generateHtmlGroupDetail, tableMultiRows } from '../ClipboardGenerator';
import ButtonDropdownComponent from '../ButtonDropdownComponent';
import { DropdownItem } from 'reactstrap';
import { toast } from 'react-toastify';

const ButtonExport = (props) => {
  const {
    data,
    exportTo,
    displayedCols,
    exportCompanyList
  } = props;


  var dataToExportForExcel = "";
  var dataToExport = "";
  var toastMsg = "";

  if(exportTo && exportTo === "CONTACT"){

    var cols = [
      {type : 'text', key : 'name', title : 'Group name'},
      {type : 'text', key : 'role', title : 'Role'}
    ];
    var title = data.title ? data.title + " " : "";

    dataToExportForExcel = generateHtmlContactDetail(data) + generateHtmlClipboard(data.groups , cols, "sheet");
    dataToExport = generateHtmlContactDetail(data) + generateHtmlClipboard(data.groups , cols, "email")
    toastMsg = "of " + title + data.fullname;
  }

  if(exportTo && exportTo === "GROUP"){

    var colsPeopleInGroupWithoutChair = [
      {type : 'fullname', key: 'fullname', title:'Contact name'},
      {type : 'link', key: 'company', title:'Company'},
      {type : 'tel', key: 'phone', title:'Phone'},
      {type : 'tel', key: 'mobile', title:'Mobile'},
      {type : 'mailto', key: 'email', title:'Email'}];

    dataToExportForExcel = generateHtmlGroupDetail(data) + generateHtmlClipboard(data.members , colsPeopleInGroupWithoutChair, "sheet");
    dataToExport = generateHtmlGroupDetail(data) + generateHtmlClipboard(data.members , colsPeopleInGroupWithoutChair, "email");
  }

  if(exportTo && exportTo === "COMPANY"){

    var cols = [{key : "fullname", type : 'fullname', title : 'Employees'},
    {type : 'text', key: 'groupsName', title:'Member of'},
    {type : 'text', key: 'phone', title:'Phone'},
    {type : 'text', key: 'mobile', title:'Mobile'},
    {type : 'mailto', key: 'email', title:'Email'}];

    var generateHtmlListClipboard = '<ul><li><strong>Company name :</strong> '
                                    + data.name + '</li><li><strong> Contacts count : </strong> '
                                    + data.companyMembership.length + ' employees</li></ul>';

    dataToExportForExcel = generateHtmlListClipboard + generateHtmlClipboard(data.companyMembership , cols, "sheet");
    dataToExport = generateHtmlListClipboard + generateHtmlClipboard(data.companyMembership , cols, "email");

  }

  if(exportTo && exportTo === "LIST"){

    dataToExportForExcel = exportCompanyList ? tableMultiRows(data , displayedCols) : generateHtmlClipboard(data , displayedCols, "sheet");
    dataToExport = exportCompanyList ? tableMultiRows(data , displayedCols) : generateHtmlClipboard(data , displayedCols, "email");
  }

  return (
    <ButtonDropdownComponent btnName="Export "  style={{display: "inline-block", marginRight: "6px"}}>
       <CopyToClipboard text={dataToExportForExcel} onCopy={() => {toast("The data " + toastMsg + "have been copied! You can now paste them into the file of your choice.", {type : "success"})}} options={{format: "text/html"}}>
           <DropdownItem className="defaultColums"><FontAwesomeIcon icon={faFileExcel}/> Export for sheet</DropdownItem>
       </CopyToClipboard>
       <CopyToClipboard text={dataToExport} onCopy={() => { toast("The data " + toastMsg + "have been copied! You can now paste them into the file of your choice.", {type : "success"}) }} options={{format: "text/html"}}>
           <DropdownItem className="defaultColums"><FontAwesomeIcon icon={faClone}/> Export</DropdownItem>
       </CopyToClipboard>
    </ButtonDropdownComponent>
  );
}

export default ButtonExport;
