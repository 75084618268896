import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUsers, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Badge, Placeholder } from 'reactstrap';

const GroupTitleHeader = (props) => {
  const {
    group,
    badgeDisabled,
    company,
    isLoading
  } = props;

  return (
    <div className="titleHeaderBox">
      <div className="titleHeaderBox-icon">
        <FontAwesomeIcon icon={company ? faBuilding : faUsers}/>
      </div>
      <div className="titleHeaderBox-body">
        {isLoading
          ? <div class="content-wrapper">
              <div class="placeholder w-700">
                <div class="animated-background"></div>
              </div>
            </div>
          : <>
              <h1 className="pannelTitle">{company ? company.name : group.name}</h1>
            </>
        }
        {!badgeDisabled ? <p><Badge color="light">{!isLoading ? group.type : "..."}</Badge></p> : null}
      </div>
    </div>
  );
}

export default GroupTitleHeader;
